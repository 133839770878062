import React, { Suspense, lazy } from 'react';
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from 'components/layout'
const CloudVideo = lazy(() => import('sections/hero/CloudVideo.js'));
const AppCloud = lazy(() => import('sections/services/AppCloud.js'));
const CloudPrecios = lazy(() => import('sections/about/CloudPrecios.js'));
const ServicesCloud = lazy(() => import('sections/services/ServicesCloud.js'));
const BotonCloud = lazy(() => import('sections/services/BotonCloud.js'));
const Llamenos = lazy(() => import('sections/hero/Llamenos.js'));
const FaqCloud = lazy(() => import('sections/services/FaqCloud.js'));
const ContactCreative = lazy(() => import('sections/contact/ContactCreative.js'));
const Footer = lazy(() => import('sections/services/Footer.js'));

class Index extends React.Component {

  render() {


    return (
      <div>
        <Helmet>
          <title>Cloud Computing: Nube gestionada de DigitalOcean, aws y Google</title>
          <meta name="description" content="Servicio de nube gestionada (Cloud) en plataformas líderes como DigitalOcean, AWS y Google Cloud. Escalabilidad y seguridad para tu proyecto en línea." />
        </Helmet>
        <Layout>
          <Suspense fallback={<div>Cargando...</div>}>
            <CloudVideo />
            <AppCloud />
            <CloudPrecios />
            <ServicesCloud />
            <FaqCloud />
            <BotonCloud />
            <Llamenos />
            <ContactCreative />
            <Footer />
          </Suspense>
        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeVideoTwoQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`